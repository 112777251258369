// src/components/Dashboard/Dashboard.jsx
import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../../api";
import CategoryList from "./CategoryList";
import ModelList from "./ModelList";
import ContactForm from "./ContactForm";

function Dashboard() {
  const [categories, setCategories] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    fetch(`${BASE_API_URL}/dashboard`, {
      headers: { Authorization: localStorage.getItem("token") },
    })
      .then((res) => {
        if (!res.ok) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        return res.json();
      })
      .then((data) => {
        setCategories(data.categories);
        setModels(data.models);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="dashboard">
      <h1>Admin Dashboard</h1>
      <ContactForm />
      <CategoryList categories={categories} setCategories={setCategories} />
      <ModelList models={models} setModels={setModels} categories={categories} />
    </div>
  );
}

export default Dashboard;
