// src/components/ProjectCard.jsx
import React from "react";
import ModelPreview from "./ModelPreview";

function ProjectCard({ project, onOpen }) {
  return (
    <div className="project-card" onClick={() => onOpen(project)}>
      <div className="model-preview-container">
      <ModelPreview
          modelPath={project.modelPath}
          pngPath={project.pngPath}      // Ensure pngPath is passed
          background={project.background} // Ensure background is passed
          scale={0.6}
        />
      </div>
      <h3>{project.title}</h3>
      <p>{project.description}</p>
    </div>
  );
}

export default ProjectCard;
