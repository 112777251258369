// src/components/ModelViewer.jsx
import React, { Suspense, useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import Loader from "./Loader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as THREE from "three";
import { BASE_API_URL } from "../api";

const STATIC_URL = BASE_API_URL.replace(/\/api$/, "") || "http://localhost:5000";

function useOptionalGLTF(modelPath, options) {
  const validPath = modelPath ? modelPath : "data:,";
  const gltf = useGLTF(validPath, undefined, options);
  return modelPath ? gltf : { scene: null };
}

function AdjustCamera({ scene }) {
  const { camera } = useThree();
  useEffect(() => {
    if (scene) {
      const box = new THREE.Box3().setFromObject(scene);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));
      cameraZ *= 1.5;
      const offsetY = size.y * 0.2;
      camera.position.set(center.x, center.y + offsetY, cameraZ);
      camera.lookAt(center);
    }
  }, [scene, camera]);
  return null;
}

function ModelContent({ fullModelPath, resourcePath, dracoLoader, scale }) {
  const { scene } = useOptionalGLTF(fullModelPath, { dracoLoader, dispose: null, resourcePath });
  return scene ? (
    <>
      <AdjustCamera scene={scene} />
      <primitive object={scene} scale={scale} />
    </>
  ) : null;
}

function ModelViewer({ modelPath, pngPath, background }) {
  const resolvedModelPath = modelPath ? `${STATIC_URL}${modelPath}` : null;
  const resolvedPngPath = pngPath ? `${STATIC_URL}${pngPath}` : null;
  
  console.log("ModelViewer Debug:", {
    modelPath,
    pngPath,
    background,
    resolvedModelPath,
    resolvedPngPath,
  });

  if (resolvedPngPath && !resolvedModelPath) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: background || "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={resolvedPngPath}
          alt="Project Preview"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          onError={(e) => console.error("Image load error:", e)}
        />
      </div>
    );
  }

  const canvasStyle = {
    width: "100vw",
    height: "100vh",
    background: background || "transparent",
  };

  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath("/draco/");
  const resourcePath = resolvedModelPath
    ? resolvedModelPath.substring(0, resolvedModelPath.lastIndexOf("/") + 1)
    : "";

  return (
    <Canvas style={canvasStyle} camera={{ position: [0, 0, 5], fov: 60 }}>
      <ambientLight intensity={0.5} />
      <directionalLight position={[2, 2, 2]} />
      <OrbitControls enableDamping enableZoom={true} />
      {resolvedModelPath && (
        <ModelContent
          fullModelPath={resolvedModelPath}
          resourcePath={resourcePath}
          dracoLoader={dracoLoader}
          scale={1}
        />
      )}
    </Canvas>
  );
}

export default function ModelViewerWithLoader(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ModelViewer {...props} />
    </Suspense>
  );
}
