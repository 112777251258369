// src/components/ModelModal.jsx
import React from "react";
import ModelViewer from "./ModelViewer";

function ModelModal({ modelPath, pngPath, background, onClose }) {
  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>&times;</span>
        <ModelViewer 
          modelPath={modelPath} 
          pngPath={pngPath} 
          background={background}
        />
      </div>
    </div>
  );
}

export default ModelModal;
