// src/pages/Contact.jsx
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { fetchJSON } from "../api";

function Contact() {
  const [contact, setContact] = useState(null);

  useEffect(() => {
    fetchJSON("/contact")
      .then((data) => setContact(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Navbar />
      <main>
        <h1>Contact Me</h1>
        {contact ? (
          <div className="contact-cards">
            <div className="contact-card">
              <i className="fab fa-discord fa-3x"></i>
              <h3>Discord</h3>
              <p>{contact.discord}</p>
            </div>
            <div className="contact-card">
              <i className="fas fa-envelope fa-3x"></i>
              <h3>Email</h3>
              <p>{contact.email}</p>
            </div>
          </div>
        ) : (
          <p>Loading contact info...</p>
        )}
      </main>
    </>
  );
}

export default Contact;
