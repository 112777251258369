// src/components/Dashboard/CategoryList.jsx
import React, { useState } from "react";
import { BASE_API_URL } from "../../api";

function CategoryList({ categories, setCategories }) {
  const [newCategory, setNewCategory] = useState("");
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [editingCategoryName, setEditingCategoryName] = useState("");

  const addCategory = async () => {
    if (!newCategory.trim()) return;
    try {
      const res = await fetch(`${BASE_API_URL}/categories`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify({ name: newCategory }),
      });
      if (!res.ok) throw new Error("Failed to add category");
      const addedCat = await res.json();
      setCategories([...categories, addedCat]);
      setNewCategory("");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const deleteCategory = async (id) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this category? This will delete all models within it."
      )
    ) {
      return;
    }
    try {
      const res = await fetch(`${BASE_API_URL}/categories/${id}`, {
        method: "DELETE",
        headers: { Authorization: localStorage.getItem("token") },
      });
      if (!res.ok) throw new Error("Failed to delete category");
      setCategories(categories.filter((cat) => cat.id !== id));
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const startEditing = (cat) => {
    setEditingCategoryId(cat.id);
    setEditingCategoryName(cat.name);
  };

  const cancelEditing = () => {
    setEditingCategoryId(null);
    setEditingCategoryName("");
  };

  const updateCategory = async (id) => {
    if (!editingCategoryName.trim()) {
      alert("Category name cannot be empty.");
      return;
    }
    try {
      const res = await fetch(`${BASE_API_URL}/categories/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify({ name: editingCategoryName }),
      });
      if (!res.ok) throw new Error("Failed to update category");
      const updatedCat = await res.json();
      setCategories(
        categories.map((cat) => (cat.id === id ? updatedCat : cat))
      );
      cancelEditing();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div className="category-list">
      <h2>Categories</h2>
      <ul>
        {categories.map((cat) => (
          <li key={cat.id}>
            {editingCategoryId === cat.id ? (
              <>
                <input
                  type="text"
                  value={editingCategoryName}
                  onChange={(e) => setEditingCategoryName(e.target.value)}
                />
                <button onClick={() => updateCategory(cat.id)}>Save</button>
                <button onClick={cancelEditing}>Cancel</button>
              </>
            ) : (
              <>
                <span>{cat.name}</span>
                <div>
                  <button onClick={() => startEditing(cat)}>Edit</button>
                  <button className="delete-btn" onClick={() => deleteCategory(cat.id)}>
                    Delete
                  </button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
      <div className="add-category">
        <input
          type="text"
          placeholder="New category name"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button onClick={addCategory}>Add Category</button>
      </div>
    </div>
  );
}

export default CategoryList;
