import React from "react";
import "./GlobalLoader.css";

const GlobalLoader = ({ onSkip }) => {
  return (
    <div className="global-loader-overlay">
      <div className="global-loader-container">
        <div className="global-loader"></div>
        <div className="loader-message">
          Loading... This may take a few seconds.
        </div>
        <button className="skip-btn" onClick={onSkip}>
          Skip
        </button>
      </div>
    </div>
  );
};

export default GlobalLoader;
