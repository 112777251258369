// src/api.js
export const BASE_API_URL = "http://pogmc.net:3762/api";

// Optionally, you can add helper functions here if needed:
export async function fetchJSON(endpoint, options = {}) {
  const res = await fetch(`${BASE_API_URL}${endpoint}`, options);
  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.error || "API Error");
  }
  return res.json();
}
