// src/components/ProjectGrid.jsx
import React, { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import ModelModal from "./ModelModal";

function ProjectGrid({ projects, onModalOpen, onModalClose }) {
  // State for modal and grid refresh
  const [selectedProject, setSelectedProject] = useState(null);
  const [showGrid, setShowGrid] = useState(true);

  // Carousel settings: 1 per page on mobile, 3 per page on desktop.
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    window.innerWidth < 768 ? 1 : 3
  );

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(window.innerWidth < 768 ? 1 : 3);
      setCurrentIndex(0);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalPages = Math.ceil(projects.length / itemsPerPage);
  
  let itemBasis;
  if (itemsPerPage === 1) {
    itemBasis = "100%";
  } else {
    itemBasis = projects.length > 3 ? "calc(100% / 3)" : `${100 / projects.length}%`;
  }

  const handleOpen = (project) => {
    setShowGrid(false);
    setSelectedProject(project);
    if (onModalOpen) onModalOpen();
  };

  const handleClose = () => {
    setSelectedProject(null);
    setShowGrid(false);
    setShowGrid(true);
    if (onModalClose) onModalClose();
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, totalPages - 1));
  };

  return (
    <div className="project-grid-container">
      {showGrid && (
        <>
          <div
            className="projects-grid-wrapper"
            style={{ padding: "0 1rem", boxSizing: "border-box" }}
          >
            <div
              className="projects-grid-inner"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: "transform 0.5s ease-in-out"
              }}
            >
              {projects.map((project) => (
                <div
                  key={project.id}
                  className="project-grid-item"
                  style={{ flex: `0 0 ${itemBasis}` }}
                >
                  <ProjectCard project={project} onOpen={handleOpen} />
                </div>
              ))}
            </div>
          </div>
          <div className="pagination-controls">
            <button onClick={handlePrev} disabled={currentIndex === 0}>
              ‹
            </button>
            <div className="pagination-dots">
              {Array.from({ length: totalPages }).map((_, i) => (
                <span key={i} className={i === currentIndex ? "active" : ""}></span>
              ))}
            </div>
            <button
              onClick={handleNext}
              disabled={currentIndex === totalPages - 1 || totalPages === 0}
            >
              ›
            </button>
          </div>
        </>
      )}
      {selectedProject && (
        <ModelModal
          modelPath={selectedProject.modelPath}
          pngPath={selectedProject.pngPath}
          background={selectedProject.background}
          onClose={handleClose}
        />
      )}
    </div>
  );
}

export default ProjectGrid;
