import React, { useState } from "react";
import { BASE_API_URL } from "../../api";
import EditModelForm from "./EditModelForm";
import AddModelForm from "./AddModelForm";

function ModelList({ models, setModels, categories }) {
  const [editingModel, setEditingModel] = useState(null);
  const [addingModel, setAddingModel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");

  // Delete model function remains unchanged
  const deleteModel = async (id) => {
    if (!window.confirm("Are you sure you want to delete this model?")) return;
    try {
      const res = await fetch(`${BASE_API_URL}/models/${id}`, {
        method: "DELETE",
        headers: { Authorization: localStorage.getItem("token") },
      });
      if (!res.ok) throw new Error("Failed to delete model");
      setModels(models.filter((model) => model.id !== id));
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  // Handles model update submissions
  const handleEditSubmit = async (updatedModel, formData) => {
    try {
      let res;
      if (formData) {
        res = await fetch(`${BASE_API_URL}/models/${updatedModel.id}`, {
          method: "PUT",
          headers: { Authorization: localStorage.getItem("token") },
          body: formData,
        });
      } else {
        res = await fetch(`${BASE_API_URL}/models/${updatedModel.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify(updatedModel),
        });
      }
      if (!res.ok) throw new Error("Failed to update model");
      const newModel = await res.json();
      setModels(models.map((model) => (model.id === newModel.id ? newModel : model)));
      setEditingModel(null);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleAddModel = (newModel) => {
    setModels([...models, newModel]);
    setAddingModel(false);
  };

  // Filter and sort models by category. If "all", show all models.
  const filteredModels = selectedCategory === "all"
    ? models
    : models.filter(model => model.categoryId === Number(selectedCategory));

  // Optional: sort the filtered models by category name (alphabetically) and then title.
  const sortedModels = [...filteredModels].sort((a, b) => {
    const categoryA = categories.find(cat => cat.id === a.categoryId)?.name || "";
    const categoryB = categories.find(cat => cat.id === b.categoryId)?.name || "";
    if (categoryA === categoryB) {
      return a.title.localeCompare(b.title);
    }
    return categoryA.localeCompare(categoryB);
  });

  return (
    <div className="model-list">
      <h2>Models</h2>
      {/* Filter by Category Dropdown */}
      <div style={{ marginBottom: "1rem" }}>
        <label htmlFor="categoryFilter" style={{ marginRight: "0.5rem" }}>
          Filter by Category:
        </label>
        <select
          id="categoryFilter"
          className="category-select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="all">All Categories</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>{cat.name}</option>
          ))}
        </select>
      </div>

      {/* Edit Model Form appears above the list */}
      {editingModel && (
        <EditModelForm
          model={editingModel}
          categories={categories}
          onSubmit={handleEditSubmit}
          onCancel={() => setEditingModel(null)}
        />
      )}

      {/* Add Model Form appears above the list */}
      {addingModel && (
        <AddModelForm
          categories={categories}
          onAdd={handleAddModel}
          onCancel={() => setAddingModel(false)}
        />
      )}

      {/* Button to toggle the Add Model Form */}
      <button onClick={() => setAddingModel(true)}>Add Model</button>

      <div className="model-table-container">
        <table className="model-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedModels.map((model) => {
              const categoryName =
                categories.find((cat) => cat.id === model.categoryId)?.name || "None";
              return (
                <tr key={model.id}>
                  <td>{model.title}</td>
                  <td>{model.description}</td>
                  <td>{categoryName}</td>
                  <td>
                    <button onClick={() => setEditingModel(model)}>Edit</button>
                    <button className="delete-btn" onClick={() => deleteModel(model.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ModelList;
