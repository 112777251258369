import React, { useState, useEffect } from "react";
import { BASE_API_URL } from "../../api";
import ReactGPicker from "react-gcolor-picker";

function EditModelForm({ model, categories, onSubmit, onCancel }) {
  const safeModel = model || { title: "", description: "", categoryId: "", background: "", pngPath: "" };

  const [title, setTitle] = useState(safeModel.title);
  const [description, setDescription] = useState(safeModel.description);
  const [categoryId, setCategoryId] = useState(safeModel.categoryId || "");
  const [background, setBackground] = useState(safeModel.background || "");
  const [modelFile, setModelFile] = useState(null);
  const [pngFile, setPngFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    setTitle(safeModel.title);
    setDescription(safeModel.description);
    setCategoryId(safeModel.categoryId || "");
    setBackground(safeModel.background || "");
  }, [model]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedModel = {
      ...model,
      title,
      description,
      categoryId: Number(categoryId) || null,
      background,
    };

    setLoading(true);
    try {
      let res;
      if (modelFile || pngFile || background) {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("categoryId", categoryId);
        formData.append("background", background);
        if (modelFile) {
          formData.append("modelFile", modelFile);
        }
        if (pngFile) {
          formData.append("pngFile", pngFile);
        }
        res = await fetch(`${BASE_API_URL}/models/${model.id}`, {
          method: "PUT",
          headers: { Authorization: localStorage.getItem("token") },
          body: formData,
        });
      } else {
        res = await fetch(`${BASE_API_URL}/models/${model.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify(updatedModel),
        });
      }
      if (!res.ok) throw new Error("Failed to update model");
      const newModel = await res.json();
      onSubmit(newModel);
    } catch (err) {
      console.error("Error updating model:", err.stack || err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="edit-model-form">
      <h3>Edit Model</h3>
      <label>
        Title:
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </label>
      <label>
        Description:
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </label>
      <label>
        Category:
        <select
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
        >
          <option value="">Select a category</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Background (color or gradient):
        <div className="background-picker-container">
          <input
            type="text"
            value={background}
            onChange={(e) => setBackground(e.target.value)}
            placeholder="Enter gradient string"
          />
          <button
            type="button"
            className="color-picker-btn"
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            Pick Gradient
          </button>
        </div>
        {showColorPicker && (
          <div className="color-picker-popup">
            <ReactGPicker
              value={background}
              gradient={true}
              solid={true}
              format="hex"
              onChange={(value) => setBackground(value)}
            />
            <button type="button" onClick={() => setShowColorPicker(false)}>
              Close
            </button>
          </div>
        )}
      </label>
      <label>
        New Model File (.glb, .gltf or .zip, optional):
        <input
          type="file"
          onChange={(e) => setModelFile(e.target.files[0])}
        />
      </label>
      <label>
        New PNG File (optional):
        <input
          type="file"
          onChange={(e) => setPngFile(e.target.files[0])}
        />
      </label>
      <button type="submit" disabled={loading}>
        {loading ? "Uploading..." : "Save"}
      </button>
      <button type="button" onClick={onCancel} disabled={loading}>
        Cancel
      </button>
    </form>
  );
}

export default EditModelForm;
