// src/pages/Home.jsx
import React, { useState, useEffect } from "react";
import ProjectGrid from "../components/ProjectGrid";
import CategoryCard from "../components/CategoryCard";
import { fetchJSON } from "../api";
import GlobalLoader from "../components/GlobalLoader";

function Home() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchJSON("/categories")
      .then((data) => setCategories(data))
      .catch((err) => console.error(err));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading && <GlobalLoader onSkip={() => setLoading(false)} />}
      <main style={{ visibility: loading ? "hidden" : "visible" }}>
        <h1>My Projects</h1>
        <CategoryCard categories={categories} />
        <hr className="main-divider" />
        {categories.map((cat) => (
          <section key={cat.id} id={`category-${cat.id}`} className="category">
            <h2>{cat.name}</h2>
            <ProjectGrid projects={cat.projects} />
          </section>
        ))}
      </main>
    </>
  );
}

export default Home;
