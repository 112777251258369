import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";

function DashboardPage() {
  // Check if a token exists in localStorage.
  const token = localStorage.getItem("token");

  // If there's no token, redirect to login.
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Otherwise, render the Dashboard component.
  return <Dashboard />;
}

export default DashboardPage;
