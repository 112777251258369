// src/components/CategoryCard.jsx
import React, { useState } from "react";

function CategoryCard({ categories }) {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded((prev) => !prev);

  const handleSelect = (id, e) => {
    // Prevent the card's onClick from toggling when clicking a button
    e.stopPropagation();
    const section = document.getElementById(`category-${id}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={`category-card ${expanded ? "expanded" : ""}`}
      onClick={handleToggle}
    >
      <h3>Categories</h3>
      {expanded && (
        <div className="category-buttons">
          {categories.map((cat) => (
            <button key={cat.id} onClick={(e) => handleSelect(cat.id, e)}>
              {cat.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default CategoryCard;
