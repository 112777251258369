import React, { useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as THREE from "three";
import { BASE_API_URL } from "../api";

const STATIC_URL = BASE_API_URL.replace(/\/api$/, "") || "http://localhost:5000";

function useOptionalGLTF(modelPath, options) {
  const validPath = modelPath ? modelPath : "data:,";
  const gltf = useGLTF(validPath, undefined, options);
  return modelPath ? gltf : { scene: null };
}

function AdjustCamera({ scene }) {
  const { camera } = useThree();
  useEffect(() => {
    if (scene) {
      const box = new THREE.Box3().setFromObject(scene);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));
      cameraZ *= 1.5;
      const offsetY = size.y * 0.2;
      camera.position.set(center.x, center.y + offsetY, cameraZ);
      camera.lookAt(center);
    }
  }, [scene, camera]);
  return null;
}

function ModelContent({ fullModelPath, resourcePath, dracoLoader, scale }) {
  const { scene } = useOptionalGLTF(fullModelPath, { dracoLoader, dispose: null, resourcePath });
  return scene ? (
    <>
      <AdjustCamera scene={scene} />
      <primitive object={scene} scale={scale} />
    </>
  ) : null;
}

function ModelPreview({ modelPath, pngPath, background, scale = 0.5 }) {
  // Compute resolved URLs:
  const resolvedPngPath = pngPath ? `${STATIC_URL}${pngPath}` : null;
  const resolvedModelPath = modelPath ? `${STATIC_URL}${modelPath}` : null;
  // Use the background prop if provided; otherwise default to "transparent"
  const effectiveBackground = background ? background : "transparent";

  // Debug Logs
  console.log("ModelPreview Debug - Input Props:", { modelPath, pngPath, background });
  console.log("ModelPreview Debug - Resolved Paths:", { resolvedPngPath, resolvedModelPath });

  // If a PNG is provided, show that image.
  if (resolvedPngPath) {
    return (
      <div style={{ width: "100%", height: "100%", background: effectiveBackground }}>
        <img
          src={resolvedPngPath}
          alt="Model Preview"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          onError={(e) => console.error("Image load error:", e)}
        />
      </div>
    );
  }

  // If neither PNG nor model path exist, show a fallback message.
  if (!resolvedPngPath && !resolvedModelPath) {
    console.warn("Warning: Both resolvedPngPath and resolvedModelPath are null!");
    return (
      <div style={{ width: "100%", height: "100%", background: "gray", textAlign: "center", paddingTop: "50px" }}>
        No model or image found.
      </div>
    );
  }

  // Otherwise, prepare to load the 3D model.
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath("/draco/");
  const resourcePath = resolvedModelPath
    ? resolvedModelPath.substring(0, resolvedModelPath.lastIndexOf("/") + 1)
    : "";

  return (
    <Canvas style={{ width: "100%", height: "100%", background: effectiveBackground }}>
      <ambientLight intensity={0.5} />
      <directionalLight position={[2, 2, 2]} />
      <OrbitControls enableZoom={false} enablePan={false} />
      {resolvedModelPath && (
        <ModelContent
          fullModelPath={resolvedModelPath}
          resourcePath={resourcePath}
          dracoLoader={dracoLoader}
          scale={scale}
        />
      )}
    </Canvas>
  );
}

export default function ModelPreviewWithLoader(props) {
  return <ModelPreview {...props} />;
}
