// src/App.jsx
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import DashboardPage from "./pages/DashboardPage";
import Navbar from "./components/Navbar";
import "./styles.css";
import "./dashboard.css";


function App() {
  return (
<BrowserRouter basename="/portfolio-client-design">
  <Navbar />
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="contact" element={<Contact />} />
    <Route path="login" element={<Login />} />
    <Route path="dashboard/*" element={<DashboardPage />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
</BrowserRouter>

  );
}

export default App;
