// src/components/Dashboard/ContactForm.jsx
import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../../api";

function ContactForm() {
  const [contact, setContact] = useState({ discord: "", email: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${BASE_API_URL}/contact`)
      .then((res) => res.json())
      .then((data) => setContact(data))
      .catch((err) => console.error(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${BASE_API_URL}/contact`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(contact),
      });
      if (!res.ok) throw new Error("Failed to update contact info");
      const updated = await res.json();
      setContact(updated);
    } catch (err) {
      console.error(err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <h2>Update Contact Info</h2>
      <label>
        Discord:
        <input
          type="text"
          value={contact.discord}
          onChange={(e) =>
            setContact({ ...contact, discord: e.target.value })
          }
          required
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          value={contact.email}
          onChange={(e) =>
            setContact({ ...contact, email: e.target.value })
          }
          required
        />
      </label>
      <button type="submit" disabled={loading}>
        {loading ? "Updating..." : "Update"}
      </button>
    </form>
  );
}

export default ContactForm;
