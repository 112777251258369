import React, { useState } from "react";
import { BASE_API_URL } from "../../api";
import ReactGPicker from "react-gcolor-picker";
// No CSS import is necessary

function AddModelForm({ categories, onAdd, onCancel }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [background, setBackground] = useState("");
  const [modelFile, setModelFile] = useState(null);
  const [pngFile, setPngFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate that at least one of modelFile, pngFile, or background is provided
    if (!title || !description || !categoryId || (!modelFile && !pngFile && !background)) {
      alert("Please fill out all fields and provide at least a model file, PNG file, or a background value.");
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("categoryId", categoryId);
    formData.append("background", background);
    if (modelFile) {
      formData.append("modelFile", modelFile);
    }
    if (pngFile) {
      formData.append("pngFile", pngFile);
    }

    setLoading(true);
    try {
      const res = await fetch(`${BASE_API_URL}/models`, {
        method: "POST",
        headers: { Authorization: localStorage.getItem("token") },
        body: formData,
      });
      if (!res.ok) throw new Error("Failed to add model");
      const newModel = await res.json();
      onAdd(newModel);
    } catch (err) {
      console.error("Add model error:", err.stack || err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="add-model-form">
      <h3>Add New Model</h3>
      <label>
        Title:
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </label>
      <label>
        Description:
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </label>
      <label>
        Category:
        <select
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
          required
        >
          <option value="">Select a category</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
      </label>
      <label>
        Background (color or gradient):
        <div className="background-picker-container">
          <input
            type="text"
            value={background}
            onChange={(e) => setBackground(e.target.value)}
            placeholder="e.g., #ff0000 or linear-gradient(180deg, #000000 0%, #ff0000 100%)"
          />
          <button
            type="button"
            className="color-picker-btn"
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            Pick Gradient
          </button>
        </div>
        {showColorPicker && (
          <div className="color-picker-popup">
            <ReactGPicker
              value={background}
              gradient={true}
              solid={true}
              format="hex"
              onChange={(value) => setBackground(value)}
            />
            <button type="button" onClick={() => setShowColorPicker(false)}>
              Close
            </button>
          </div>
        )}
      </label>
      <label>
        Model File (.glb, .gltf or .zip, optional):
        <input
          type="file"
          className="file-input"
          onChange={(e) => setModelFile(e.target.files[0])}
        />
      </label>
      <label>
        PNG File (optional):
        <input
          type="file"
          className="file-input"
          onChange={(e) => setPngFile(e.target.files[0])}
        />
      </label>
      <button type="submit" disabled={loading}>
        {loading ? "Uploading..." : "Add Model"}
      </button>
      <button type="button" onClick={onCancel} disabled={loading}>
        Cancel
      </button>
    </form>
  );
}

export default AddModelForm;
